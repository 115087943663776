@import "~roboto-fontface/css/roboto/roboto-fontface.css";
@import "~material-design-icons/iconfont/material-icons.css";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$svg-primary: mat-palette($mat-indigo);
$svg-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$svg-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$svg-theme: mat-light-theme(
    (
        color: (
            primary: $svg-primary,
            accent: $svg-accent,
            warn: $svg-warn,
        ),
    )
);

$dark-primary: mat-palette($mat-indigo);
$dark-accent: mat-palette($mat-amber, A200, A100, A400);
$dark-warn: mat-palette($mat-deep-orange);
$dark-theme: mat-dark-theme(
    (
        color: (
            primary: $dark-primary,
            accent: $dark-accent,
            warn: $dark-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($svg-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.dark-theme {
    @include angular-material-theme($dark-theme);
}

@import '~ngx-toastr/toastr';



.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-results {
  text-align: center;
  padding: 10px;
  font-style: italic;
  color: #757575;
  border-bottom: 1px solid #e0e0e0;
}

::ng-deep .dark-theme .no-results {
  border-color: #595959;
  color:rgb(199, 199, 196);
}

.help-tooltip {
  font-size: 0.9em;
}

.filter-option > .mat-pseudo-checkbox {
    display: none !important;
}